import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ToasterModule } from 'angular2-toaster';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InfomarineHttpInterceptor } from './@core/interceptors/http.infomarine-interceptor';

import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, POSITION } from 'ngx-ui-loader';

import { UserIdleModule } from 'angular-user-idle';
import { GroupVesselService } from './@core/services/group-vessel.service';

/**
 * Loader configuration
 */
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#2578b2',
  bgsPosition: POSITION.bottomRight,
  bgsSize: 50
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToasterModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule,
    UserIdleModule.forRoot({
      idle: 600,
      timeout: 300,
      ping: 120
    })
  ],
  providers: [
    GroupVesselService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: InfomarineHttpInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
