import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service for checking group vessel change
 */
@Injectable({
  providedIn: 'root'
})
export class GroupVesselService {

    /**
     * Subject to notify if vessel group changed
     */
    public isGroupVesselChanged: Subject<boolean> = new Subject<boolean>();

    /**
     * @ignore
     */
    constructor() { }

}
