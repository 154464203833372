import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 *
 * Service for Authorization and Authentication
 *
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   *
   * @ignore
   *
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   *
   * Signs in user to system
   *
   * @param credentials User credentials to login
   *
   */
  public login(credentials): Observable<string> {
    return this.http.post<string>('account/login', credentials).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
  *
  * Checks if user is authorized and extends session
  *
  */
  public isAuthorized(): Observable<any> {
    return this.http.get('account/authorized').pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
  *
  * Checks if user is authorized
  *
  */
  public isAuthorizedNoExt(): Observable<any> {
    return this.http.get('account/authorized?ext=false').pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   *
   * Signs out user from system
   *
   */
  public logout(): Observable<any> {
    return this.http.post('account/logout', {}).pipe(
      map(response => {
        return response;
      })
    );
  }
}
