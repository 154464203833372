import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ShipownersService } from 'src/app/pages/shipowners/shipowners.service';
import { map } from 'rxjs/operators';

/**
 *
 * Resolver for Admin role
 *
 */
@Injectable({
  providedIn: 'root'
})
export class AdminResolverService implements Resolve<boolean> {

  /**
   *
   * @ignore
   *
   */
  constructor(
    private http: ShipownersService
  ) { }

  /**
   * Resolves if user have admin permissions
   */
  resolve(): Observable<boolean> {
    return this.http.isAdmin().pipe(
      map(response => {
        return response;
      })
    );
  }

}
