import { Component } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

/**
 * App's main component
 */
@Component({
  selector: 'infomarine-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
 }
