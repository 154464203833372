import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';

/**
 * Login RouteGuard
 */
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  /**
   *
   * @ignore
   *
   */
  constructor(
    private router: Router,
    private http: AuthService,
    private toastr: ToasterService
  ) { }

  /**
   *
   * Determines if user is logged in and then navigate to application
   *
   */
  canActivate(): Observable<boolean> {
    return this.http.isAuthorized().pipe(
      map(response => {
        if ((response.signed_in === 'true')) {
          this.router.navigate(['']);
          return false;
        } else {
          return true;
        }
      }));
  }
}
