import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

/**
 * Service for handling shipowners
 */
@Injectable({
  providedIn: 'root'
})
export class ShipownersService {

  /**
   * @ignore
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Returns list of shipowners
   */
  public getShipowners(): Observable<Array<any>> {
    return this.http.get<Array<any>>('admin/shipowners_updated').pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Determines if signed in user is an admin
   */
  public isAdmin(): Observable<any> {
    return this.http.get<any>('admin/is_admin').pipe(
      map(response => {
        return response.is_admin || null;
      })
    );
  }

  /**
   * Assign vessels from choosen user's fleet to signed in user's account
   *
   * @param id Choosen user's identifier
   */
  public copyUserVessels(id: string): Observable<any> {
    const param = id ? id : '-1';
    return this.http.get<any>('admin/copy_user_vessel/' + param).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Returns choosen user's vessels list
   *
   * @param id User's identifier
   */
  public getUserVessels(id: string): Observable<any> {
    const param = id ? id : '-1';
    return this.http.get<any>('admin/user_vessels/' + param).pipe(
      map(response => {
        return response;
      })
    );
  }

    /**
   * Returns choosen user's fleets list
   *
   * @param id User's identifier
   */
     public getUserFleets(id: string): Observable<any> {
      const param = id ? id : '-1';
      return this.http.get<any>('admin/user_fleets/' + param).pipe(
        map(response => {
          return response;
        })
      );
    }

  /**
   * Saves new vessel list for choosen user
   *
   * @param id Choosen user's identifier
   * @param vessels Array of vessels
   */
  public saveUserVessel(id: string, vessels: Array<{ vessel_id: number }>): Observable<any> {
    const ids: Array<number> = [];
    vessels.forEach(e => { ids.push(e['vessel_id']); });
    return this.http.post<any>('admin/user_vessel', { id: id, vessels: ids }).pipe(
      map(response => {
        return response;
      })
    );
  };

  public saveUserFleet(id: string, fleets: Array<{ fleet_id: number }>): Observable<any> {
    const ids: Array<number> = [];
    fleets.forEach(e => { ids.push(e['fleet_id']); });
    return this.http.post<any>('admin/user_fleet', { id: id, fleets: ids }).pipe(
      map(response => {
        return response;
      })
    );
  }
}
