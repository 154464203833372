import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './@core/guards/auth.guard';
import { LoginGuard } from './@core/guards/login.guard';
import { AdminResolverService } from './@shared/resolvers/admin-resolver.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/pages.module#PagesModule',
    canActivate: [AuthGuard],
    resolve: {
      admin: AdminResolverService
    }
  }, {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
    canActivate: [LoginGuard]
  }, {
    path: 'not-found',
    loadChildren: './not-found/not-found.module#NotFoundModule'
  }, {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
